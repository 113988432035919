var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",style:({
      'min-height': _vm.container_min_height,
      'margin-top': _vm.statusBarHeight,
    })},[_c('nav-header'),_c('main',{class:_vm.commonQuestions === 0 ? 'commonQuestions_block' : 'commonQuestions_none'},[_vm._m(0),(_vm.videoCaseData.isExist)?_c('div',{staticClass:"case-card"},[_c('div',{staticClass:"title"},[_c('i',{staticClass:"video-icon"}),_c('div',{staticClass:"doctor-name"},[_vm._v(" "+_vm._s(_vm.videoCaseData.doctorName)+" ")]),(_vm.videoCaseData.status === 25)?_c('router-link',{attrs:{"to":{
              name: 'casePayment',
              params: { id: _vm.videoCaseData.id },
            },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
return [_c('span',{staticClass:"payfor_now",on:{"click":navigate}},[_vm._v("立即付款")])]}}],null,false,3547106304)}):_vm._e()],1),_c('div',{staticClass:"content"},[_vm._v(_vm._s(_vm.videoCaseData.date))]),_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"rounded-primary",on:{"click":function($event){return _vm.toDetail(_vm.videoCaseData.id, _vm.videoCaseData.status)}}},[_vm._v(" 詳情 ")]),(_vm.videoCaseData.status === 0)?_c('router-link',{attrs:{"to":{
              name: 'reservationPayment',
              params: {
                id: _vm.videoCaseData.id,
              },
              query: {
                loginType: this.$route.query.loginType
              },
            },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
return [_c('button',{staticClass:"rounded-primary",on:{"click":navigate}},[_vm._v("付款")])]}}],null,false,2006737082)}):_c('router-link',{attrs:{"to":{
              name: 'videoClinic',
              params: { roomNumber: _vm.videoCaseData.roomNumber },
              query: { loginType: this.$route.query.loginType },
            },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
return [(_vm.isHaveDoctorLogined === 1 && _vm.isVideoEnd != 2)?_c('button',{staticClass:"rounded-primary",on:{"click":navigate}},[_vm._v(" 進入問診室 ")]):(_vm.videoCaseData.status > 15 && _vm.isVideoEnd === 2)?_c('button',{staticClass:"rounded-primary",attrs:{"disabled":!_vm.imCaseData.available}},[_vm._v(" 問診室已結束 ")]):(_vm.videoCaseData.status < 10)?_c('button',{staticClass:"rounded-primary",attrs:{"disabled":!_vm.imCaseData.available}},[_vm._v(" 進入問診室 ")]):(_vm.videoCaseData.status == 10 && _vm.isVideoEnd != 2)?_c('button',{staticClass:"rounded-primary",attrs:{"disabled":!_vm.videoCaseData.times},on:{"click":navigate}},[_vm._v(" 進入問診室 ")]):_c('button',{staticClass:"rounded-primary",attrs:{"disabled":!_vm.imCaseData.available},on:{"click":navigate}},[_vm._v(" 問診室已關閉 ")])]}}],null,false,4119293319)})],1)]):_vm._e(),(_vm.videoCaseData.isExist)?_c('progress-bar',{staticClass:"progress-bar",attrs:{"status":_vm.videoCaseData.status,"dateList":_vm.videoCaseData.dateList,"type":"video"}}):_c('div',{class:this.$store.state.uniappLogin == 'true'
            ? 'video_frame_gyt'
            : 'video_frame',style:({
          width: _vm.video_frame_width,
          'padding-top': _vm.video_frame_top,
          position: 'absolute',
          top: _vm.video_frame_absolute_top,
        })},[_c('router-link',{attrs:{"to":{
            name: 'reservation',
            query: {
              type: 'video',
              loginType: this.$route.query.loginType,
              memberId: this.$route.query.memberId,
              loginTypeKy: this.$route.query.loginTypeKy,
              loginTypeYy: this.$route.query.loginTypeYy,
              merchantCode: this.$route.query.merchantCode,
              merchantAccessToken:  this.$route.query.merchantAccessToken
            },
          },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var navigate = ref.navigate;
return [_c('button',{staticClass:"rounded-primary new-reservation frame",style:({ position: 'relative', bottom: _vm.frame_bottom }),on:{"click":navigate}},[_vm._v(" 立即預約 ")])]}}])})],1),(_vm.imCaseData.isExist)?_c('div',{staticClass:"case-card",style:({ 'margin-top': _vm.case_card_top })},[_c('div',{staticClass:"title"},[_c('i',{staticClass:"im-icon"}),_c('div',{staticClass:"doctor-name"},[_vm._v(_vm._s(_vm.imCaseData.doctorName))]),(_vm.imCaseData.status === 25)?_c('router-link',{attrs:{"to":{
              name: 'reservationPayment',
              query: {
                id: _vm.imCaseData.id,
                loginType: this.$route.query.loginType,
                loginTypeKy: this.$route.query.loginTypeKy,
              },
            },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
return [_c('span',{staticClass:"payfor_now",on:{"click":navigate}},[_vm._v("立即付款")])]}}],null,false,3547106304)}):_vm._e()],1),_c('div',{staticClass:"content"},[_c('div',[_vm._v(_vm._s(_vm.imCaseData.date[0]))]),_c('div',[_vm._v(_vm._s(_vm.imCaseData.date[1]))])]),_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"rounded-primary",on:{"click":function($event){return _vm.toDetail(_vm.imCaseData.id, _vm.imCaseData.status)}}},[_vm._v(" 詳情 ")]),(_vm.imCaseData.status === 0)?_c('router-link',{attrs:{"to":{
              name: 'reservationPayment',
              params: {
                id: _vm.imCaseData.id,
              },
              query: {
                loginType: this.$route.query.loginType,
                loginTypeKy: this.$route.query.loginTypeKy,
              },
            },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
return [_c('button',{staticClass:"rounded-primary",on:{"click":navigate}},[_vm._v("付款")])]}}],null,false,2006737082)}):_c('router-link',{attrs:{"to":{
              name: 'messageClinic',
              params: { roomNumber: _vm.imCaseData.roomNumber },
            },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
return [_c('button',{staticClass:"rounded-primary",attrs:{"disabled":!_vm.imCaseData.available},on:{"click":navigate}},[_vm._v(" 進入問診室 ")])]}}],null,false,4118841654)})],1)]):_vm._e(),(_vm.imCaseData.isExist)?_c('progress-bar',{staticClass:"progress-bar",attrs:{"status":_vm.imCaseData.status,"dateList":_vm.imCaseData.dateList,"type":"text"}}):_c('div',{class:this.$store.state.uniappLogin||this.$store.state.uniappLoginYy
            ? 'characters_frame_gyt'
            : 'characters_frame',style:({
          width: _vm.characters_frame_width,
          'padding-top': _vm.characters_frame_top,
          position: 'absolute',
          top: _vm.characters_frame_absolute_top,
        })},[_c('router-link',{attrs:{"to":{ name: 'shieldingPager', query: { type: 'text' } },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var navigate = ref.navigate;
return [_c('button',{staticClass:"rounded-primary new-reservation frame",style:({ position: 'relative', bottom: _vm.frame_bottom }),on:{"click":navigate}},[_vm._v(" 立即預約 ")])]}}])})],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index_wu"},[_c('p',{staticClass:"index_wu_p"},[_vm._v("預約線上問診")]),_c('div',{staticClass:"index_wu_div",staticStyle:{"color":"#fff"}},[_c('div',[_vm._v("足")]),_c('div',[_vm._v("不")]),_c('div',[_vm._v("出")]),_c('div',[_vm._v("戶")]),_c('div',[_vm._v("預")]),_c('div',[_vm._v("約")]),_c('div',[_vm._v("醫")]),_c('div',[_vm._v("生")])])])}]

export { render, staticRenderFns }