<template>
	<div class="container" :style="{
      'min-height': container_min_height,
      'margin-top': statusBarHeight,
    }">
		<nav-header></nav-header>
		<!-- <el-carousel height="50vw" arrow="never">
            <el-carousel-item v-for="(ads, index) in carousel" :key="index">
                <a :href="ads.link">
                    <img
                        class="carousel-img"
                        :src="ads.image"
                        :alt="ads.title"
                    />
                </a>
            </el-carousel-item>
        </el-carousel> -->
		<main :class="
        commonQuestions === 0 ? 'commonQuestions_block' : 'commonQuestions_none'
      ">
			<div class="index_wu">
				<!-- <img src="../../assets/img/wzwz.png"/> -->
				<p class="index_wu_p">預約線上問診</p>
				<div style="color: #fff" class="index_wu_div">
					<div>足</div>
					<div>不</div>
					<div>出</div>
					<div>戶</div>
					<div>預</div>
					<div>約</div>
					<div>醫</div>
					<div>生</div>
				</div>
			</div>
			
			
			<div v-if="videoCaseData.isExist" class="case-card">
				<div class="title">
					<i class="video-icon"></i>
					<div class="doctor-name">
						{{ videoCaseData.doctorName }}
					</div>

					<router-link v-if="videoCaseData.status === 25" :to="{
              name: 'casePayment',
              params: { id: videoCaseData.id },
            }" custom v-slot="{ navigate }">
						<span class="payfor_now" @click="navigate">立即付款</span>
					</router-link>
				</div>
				<div class="content">{{ videoCaseData.date }}</div>
				<div class="btn-group">
					<button class="rounded-primary" @click="toDetail(videoCaseData.id, videoCaseData.status)">
						詳情
					</button>
					<router-link :to="{
              name: 'reservationPayment',
              params: {
                id: videoCaseData.id,
              },
              query: {
                loginType: this.$route.query.loginType
              },
            }" custom v-slot="{ navigate }" v-if="videoCaseData.status === 0">
						<button class="rounded-primary" @click="navigate">付款</button>
					</router-link>
					<router-link :to="{
              name: 'videoClinic',
              params: { roomNumber: videoCaseData.roomNumber },
              query: { loginType: this.$route.query.loginType },
            }" custom v-slot="{ navigate }" v-else>
						<!-- videoCaseData.chatroom.doctor_logined == "1" -->
						<button @click="navigate" class="rounded-primary"
							v-if="isHaveDoctorLogined === 1 && isVideoEnd != 2">
							進入問診室
						</button>
						<button class="rounded-primary" :disabled="!imCaseData.available"
							v-else-if="videoCaseData.status > 15 && isVideoEnd === 2">
							問診室已結束
						</button>
						<button class="rounded-primary" :disabled="!imCaseData.available"
							v-else-if="videoCaseData.status < 10">
							進入問診室
						</button>
						<button class="rounded-primary" @click="navigate" :disabled="!videoCaseData.times"
							v-else-if="videoCaseData.status == 10 && isVideoEnd != 2">
							進入問診室
						</button>

						<button :disabled="!imCaseData.available" class="rounded-primary" @click="navigate" v-else>
							問診室已關閉
						</button>
					</router-link>
				</div>
			</div>
			<progress-bar v-if="videoCaseData.isExist" :status="videoCaseData.status" :dateList="videoCaseData.dateList"
				type="video" class="progress-bar"></progress-bar>
			<div :class="
          this.$store.state.uniappLogin == 'true'
            ? 'video_frame_gyt'
            : 'video_frame'
        " :style="{
          width: video_frame_width,
          'padding-top': video_frame_top,
          position: 'absolute',
          top: video_frame_absolute_top,
        }" v-else>
				<router-link :to="{
            name: 'reservation',
            query: {
              type: 'video',
              loginType: this.$route.query.loginType,
              memberId: this.$route.query.memberId,
              loginTypeKy: this.$route.query.loginTypeKy,
              loginTypeYy: this.$route.query.loginTypeYy,
              merchantCode: this.$route.query.merchantCode,
              merchantAccessToken:  this.$route.query.merchantAccessToken
            },
          }" v-slot="{ navigate }" custom>
					<button @click="navigate" class="rounded-primary new-reservation frame"
						:style="{ position: 'relative', bottom: frame_bottom }">
						立即預約
					</button>
				</router-link>
			</div>
			<div v-if="imCaseData.isExist" class="case-card" :style="{ 'margin-top': case_card_top }">
				<div class="title">
					<i class="im-icon"></i>
					<div class="doctor-name">{{ imCaseData.doctorName }}</div>
					<router-link v-if="imCaseData.status === 25" :to="{
              name: 'reservationPayment',
              query: {
                id: imCaseData.id,
                loginType: this.$route.query.loginType,
                loginTypeKy: this.$route.query.loginTypeKy,
              },
            }" custom v-slot="{ navigate }">
						<span class="payfor_now" @click="navigate">立即付款</span>
					</router-link>
				</div>
				<div class="content">
					<div>{{ imCaseData.date[0] }}</div>
					<div>{{ imCaseData.date[1] }}</div>
				</div>
				<div class="btn-group">
					<button class="rounded-primary" @click="toDetail(imCaseData.id, imCaseData.status)">
						詳情
					</button>
					<router-link :to="{
              name: 'reservationPayment',
              params: {
                id: imCaseData.id,
              },
              query: {
                loginType: this.$route.query.loginType,
                loginTypeKy: this.$route.query.loginTypeKy,
              },
            }" custom v-slot="{ navigate }" v-if="imCaseData.status === 0">
						<button class="rounded-primary" @click="navigate">付款</button>
					</router-link>
					<router-link :to="{
              name: 'messageClinic',
              params: { roomNumber: imCaseData.roomNumber },
            }" custom v-slot="{ navigate }" v-else>
						<button class="rounded-primary" :disabled="!imCaseData.available" @click="navigate">
							進入問診室
						</button>
					</router-link>
				</div>
			</div>
			<progress-bar v-if="imCaseData.isExist" :status="imCaseData.status" :dateList="imCaseData.dateList"
				type="text" class="progress-bar"></progress-bar>
			<div :class="
          this.$store.state.uniappLogin||this.$store.state.uniappLoginYy
            ? 'characters_frame_gyt'
            : 'characters_frame'
        " :style="{
          width: characters_frame_width,
          'padding-top': characters_frame_top,
          position: 'absolute',
          top: characters_frame_absolute_top,
        }" v-else>
				<!-- <router-link
          :to="{ name: 'reservation', query: { type: 'text' } }"
          v-slot="{ navigate }"
          custom
        >
          <button
            @click="navigate"
            class="rounded-primary new-reservation frame"
            :style="{ position: 'relative', bottom: frame_bottom }"
          >
            立即預約
          </button>
          
        </router-link> -->

				<router-link :to="{ name: 'shieldingPager', query: { type: 'text' } }" v-slot="{ navigate }" custom>
					<button @click="navigate" class="rounded-primary new-reservation frame"
						:style="{ position: 'relative', bottom: frame_bottom }">
						立即預約
					</button>
				</router-link>
			</div>
		</main>
		<!-- 已添加轮询 -->
		<!-- <button class="reload" @click="reload">
            <i class="icon-reload"></i>
        </button> -->
	</div>
</template>

<script>
	import memberLoginMixin from "../../mixins/memberLogin";
	import {
		patientsHome,
		poolingPatientsHome
	} from "@/api";
	import navHeader from "../../components/navHeader.vue";
	import progressBar from "../../components/progressBar.vue";
	import {
		mapState
	} from "vuex";
	import {
		isSafari
	} from "@/assets/js";
	export default {
		mixins: [memberLoginMixin],
		components: {
			navHeader,
			progressBar
		},
		data: function() {
			return {
				getAgent:'',
				statusBarHeight: "0px",
				commonQuestions: 0,
				screenWidth: document.body.clientWidth,
				screenHeight: document.body.clientHeight,
				video_frame_width: "82%",
				video_frame_top: "64%",
				characters_frame_width: "82%",
				characters_frame_top: "64%",
				characters_frame_absolute_top: "85vh",
				frame_bottom: "7vh",
				container_min_height: "121vh",
				video_frame_absolute_top: "45vh",
				case_card_top: "0vh",
				videoCaseData: {
					id: 0,
					isExist: false,
					doctorName: "",
					date: "",
					roomNumber: 0,
					available: false,
					status: 0,
					dateList: [],
					times: true,
				},
				imCaseData: {
					id: 0,
					isExist: false,
					doctorName: "",
					date: "",
					roomNumber: 0,
					available: false,
					status: 0,
					dateList: [],
				},
				carousel: [],
				memberId: "",

				merchantCode: "",
				merchantAccessToken: "",

				poolingId: -1,
				isOpenHistory: "false",
				isVideoEnd: 0,
				isDoneShowDialog: 0,

				isHaveDoctorLogined: 0,
			};
		},
		watch: {
			commonQuestions_s(val) {
				this.commonQuestions = val;
			},
			videoCaseData_isExist() {
				this.clientWidths();
			},
			imCaseData_isExist() {
				this.clientWidths();
			},
		},
		computed: {
			commonQuestions_s() {
				return this.$store.state.commonQuestions_display;
			},
			...mapState({
				token: (state) => state.user.token,
			}),
			videoCaseData_isExist() {
				return this.videoCaseData.isExist;
			},
			imCaseData_isExist() {
				return this.imCaseData.isExist;
			},
		},
		mounted() {
			const that = this;
			// 实时监听手机端宽度是否修改 修改时触发
			window.onresize = () => {
				// window.screenWidth = document.body.clientWidth;
				// that.screenWidth = window.screenWidth;
				this.clientWidths();
			};
		},
		methods: {
			closeBrowser() {
				var setInt =
					"http://port" +
					Math.ceil(Math.random() * 3) +
					".theimed.com/?isApp=ecd8da6fe560f7d42a673f19e0ccb238";

				// if (this.isDoneShowDialog != 1 && this.isDoneShowDialog != 0) {
				//   return;
				// }
				if (this.$store.state.isShowLangingPage != "009") {
					return;
				}
				if (
					this.videoCaseData.doctorName != "" ||
					this.videoCaseData.doctorName != null
				) {
					this.$confirm(
							"如閣下為新冠肺炎確診者，請前往新冠肺炎視像問診專頁進行預約。(如當前有訂單請點擊正常預約或者右上角的關閉)",
							"請注意", {
								confirmButtonText: "前往專頁",
								cancelButtonText: " 正常預約",
							}
						)
						.then(() => {
							window.location.href = setInt;
						})
						.catch(() => {});
					this.$store.state.isShowLangingPage = "007";
				}
			},

			clientWidths() {
				if (document.body.clientWidth >= 540) {
					this.video_frame_width = "70%";
					this.video_frame_top = "55%";
					this.characters_frame_width = "70%";
					this.characters_frame_top = "55%";
				}

				// 儅視像問診有預約時 樣式修改
				if (this.videoCaseData.isExist === true) {
					if (document.body.clientWidth === 240) {
						this.frame_bottom = "12vh";
						this.container_min_height = "183vh";
						this.characters_frame_absolute_top = "135vh";
					} else if (document.body.clientWidth === 412) {
						this.frame_bottom = "8vh";
						this.container_min_height = "145vh";
						this.characters_frame_absolute_top = "105vh";
					} else if (document.body.clientWidth === 800) {
						this.frame_bottom = "8vh";
						this.container_min_height = "122vh";
						this.characters_frame_absolute_top = "87vh";
					} else if (document.body.clientWidth === 640) {
						this.frame_bottom = "18vh";
						this.container_min_height = "228vh";
						this.characters_frame_absolute_top = "130vh";
					} else if (document.body.clientWidth >= 1200) {
						this.frame_bottom = "8vh";
						this.container_min_height = "193vh";
						this.characters_frame_absolute_top = "105vh";
					} else if (document.body.clientWidth === 384) {
						this.frame_bottom = "8vh";
						this.container_min_height = "144vh";
						this.characters_frame_absolute_top = "105vh";
					} else if (document.body.clientWidth === 280) {
						this.frame_bottom = "6.2vh";
						this.characters_frame_absolute_top = "110vh";
						this.container_min_height = "138vh";
					} else if (document.body.clientWidth === 320) {
						this.frame_bottom = "8vh";
						this.characters_frame_absolute_top = "112vh";
						this.container_min_height = "155vh";
					} else if (document.body.clientWidth === 540) {
						this.frame_bottom = "8vh";
						this.characters_frame_absolute_top = "105vh";
						this.container_min_height = "147vh";
					} else if (document.body.clientWidth === 768) {
						this.frame_bottom = "8vh";
						this.characters_frame_absolute_top = "95vh";
						this.container_min_height = "138vh";
					} else if (document.body.clientWidth === 1024) {
						this.frame_bottom = "8vh";
						this.characters_frame_absolute_top = "88vh";
						this.container_min_height = "130vh";
					} else if (document.body.clientWidth === 360) {
						this.frame_bottom = "8vh";
						this.container_min_height = "145vh";
						this.characters_frame_absolute_top = "109vh";
					} else if (
						document.body.clientWidth === 375 &&
						document.body.clientHeight >= 800
					) {
						this.frame_bottom = "6vh";
						this.container_min_height = "141vh";
						this.characters_frame_absolute_top = "105vh";
					} else if (document.body.clientWidth === 375) {
						this.frame_bottom = "7vh";
						this.container_min_height = "141vh";
						this.characters_frame_absolute_top = "105vh";
					} else if (document.body.clientWidth === 411) {
						this.frame_bottom = "6vh";
						this.container_min_height = "141vh";
						this.characters_frame_absolute_top = "105vh";
					} else if (document.body.clientWidth === 756) {
						this.frame_bottom = "5vh";
						this.container_min_height = "141vh";
						this.characters_frame_absolute_top = "105vh";
					}
					if (document.body.clientWidth === 428) {
						this.frame_bottom = "6vh";
						this.container_min_height = "141vh";
						this.characters_frame_absolute_top = "105vh";
					} else {
						this.frame_bottom = "8vh";
						this.container_min_height = "141vh";
						this.characters_frame_absolute_top = "105vh";
					}
					// 儅文字問診有預約時 樣式修改
				} else if (this.imCaseData.isExist === true) {
					if (document.body.clientWidth === 600) {
						this.frame_bottom = "8vh";
						this.case_card_top = "20vh";
						this.container_min_height = "121vh";
					} else if (document.body.clientWidth >= 1200) {
						this.frame_bottom = "8vh";
						this.case_card_top = "75vh";
						this.container_min_height = "175vh";
					} else if (document.body.clientWidth === 800) {
						this.frame_bottom = "8vh";
						this.case_card_top = "20vh";
						this.container_min_height = "121vh";
					} else if (document.body.clientWidth === 756) {
						this.frame_bottom = "4vh";
						this.case_card_top = "20vh";
						this.container_min_height = "121vh";
					} else if (document.body.clientWidth === 640) {
						this.frame_bottom = "20vh";
						this.case_card_top = "95vh";
						this.video_frame_absolute_top = "55vh";
						this.container_min_height = "121vh";
					} else if (document.body.clientWidth === 384) {
						this.frame_bottom = "8vh";
						this.case_card_top = "25vh";
						this.container_min_height = "121vh";
					} else if (
						document.body.clientWidth === 412 &&
						document.body.clientHeight === 799
					) {
						this.frame_bottom = "8vh";
						this.case_card_top = "25vh";
						this.container_min_height = "121vh";
					} else if (document.body.clientWidth === 412) {
						this.frame_bottom = "8vh";
						this.case_card_top = "20vh";
						this.container_min_height = "121vh";
					} else if (document.body.clientWidth === 480) {
						this.frame_bottom = "8vh";
						this.case_card_top = "20vh";
						this.container_min_height = "121vh";
					} else if (document.body.clientWidth === 240) {
						this.frame_bottom = "12vh";
						this.case_card_top = "35vh";
						this.container_min_height = "121vh";
					} else if (
						document.body.clientWidth === 360 &&
						document.body.clientHeight === 686
					) {
						this.frame_bottom = "8vh";
						this.case_card_top = "25vh";
						this.container_min_height = "121vh";
					} else if (document.body.clientWidth === 360) {
						this.frame_bottom = "8vh";
						this.case_card_top = "20vh";
						this.container_min_height = "121vh";
					} else if (document.body.clientWidth === 411) {
						this.frame_bottom = "8vh";
						this.container_min_height = "121vh";
						this.case_card_top = "20vh";
					} else if (document.body.clientWidth === 320) {
						this.frame_bottom = "9vh";
						this.case_card_top = "28vh";
						this.container_min_height = "121vh";
					} else if (document.body.clientWidth === 280) {
						this.frame_bottom = "6.2vh";
						this.case_card_top = "15vh";
						this.container_min_height = "121vh";
					} else if (document.body.clientWidth === 768) {
						this.case_card_top = "25vh";
						this.frame_bottom = "8vh";
						this.container_min_height = "121vh";
					} else if (document.body.clientWidth === 1024) {
						this.frame_bottom = "8vh";
						this.container_min_height = "121vh";
						this.case_card_top = "25vh";
					} else if (document.body.clientWidth === 540) {
						this.case_card_top = "25vh";
						this.frame_bottom = "8vh";
						this.container_min_height = "121vh";
					} else if (document.body.clientWidth === 414) {
						this.case_card_top = "25vh";
						this.frame_bottom = "8vh";
						this.container_min_height = "121vh";
					} else if (
						document.body.clientWidth === 375 &&
						document.body.clientHeight === 807
					) {
						this.frame_bottom = "8vh";
						this.case_card_top = "20vh";
						this.container_min_height = "121vh";
					} else if (
						document.body.clientWidth === 375 &&
						document.body.clientHeight === 885
					) {
						this.frame_bottom = "6vh";
						this.case_card_top = "15vh";
						this.container_min_height = "121vh";
					} else if (document.body.clientWidth === 428) {
						this.frame_bottom = "6vh";
						this.case_card_top = "15vh";
						this.container_min_height = "121vh";
					} else if (document.body.clientWidth === 393) {
						this.frame_bottom = "8vh";
						this.case_card_top = "20vh";
						this.container_min_height = "121vh";
					} else {
						this.frame_bottom = "8vh";
						this.case_card_top = "15vh";
						this.container_min_height = "121vh";
					}
				} else {
					if (document.body.clientWidth === 240) {
						this.container_min_height = "148vh";
						this.characters_frame_absolute_top = "100vh";
						this.frame_bottom = "12vh";
					} else if (document.body.clientWidth === 280) {
						this.container_min_height = "102.5vh";
						this.characters_frame_absolute_top = "75vh";
						this.frame_bottom = "6.2vh";
					} else if (
						document.body.clientWidth === 411 &&
						(document.body.clientHeight === 823 ||
							document.body.clientHeight >= 900)
					) {
						this.characters_frame_absolute_top = "81vh";
						this.container_min_height = "113vh";
						this.frame_bottom = "7vh";
					} else if (
						document.body.clientWidth === 375 &&
						document.body.clientHeight >= 812
					) {
						this.characters_frame_absolute_top = "79vh";
						this.container_min_height = "109vh";
						this.frame_bottom = "7vh";
					} else if (
						document.body.clientWidth === 375 ||
						document.body.clientWidth === 320 ||
						document.body.clientWidth === 411
					) {
						this.container_min_height = "121vh";
						this.frame_bottom = "8vh";
					} else if (
						document.body.clientWidth === 768 ||
						document.body.clientWidth === 1024 ||
						document.body.clientWidth === 540
					) {
						this.characters_frame_absolute_top = "90vh";
						this.container_min_height = "132vh";
						this.frame_bottom = "7vh";
					} else if (document.body.clientWidth === 414) {
						this.container_min_height = "121vh";
						this.frame_bottom = "7vh";
					} else if (document.body.clientWidth === 428) {
						this.container_min_height = "121vh";
						this.characters_frame_absolute_top = "85vh";
						this.frame_bottom = "6vh";
					} else {
						this.container_min_height = "121vh";
						this.characters_frame_absolute_top = "85vh";
						this.frame_bottom = "8vh";
					}
				}
			},
			registerPooling() {
				if (this.poolingId !== -1) return;
				this.poolingId = setInterval(this.poolingGetData, 3000);
			},
			poolingGetData() {
				this.getData(true);
			},
			// 已添加轮询
			// reload() {
			//     location.reload();
			// },
			toDetail(id, status) {
				if (status > 15) {
					this.$router.push({
						name: "caseDetail",
						params: {
							id
						},
						query: {
							loginType: this.$route.query.loginType,
							loginTypeKy: this.$route.query.loginTypeKy,
						},
					});
				} else {
					this.$router.push({
						name: "reservationDetail",
						params: {
							id
						},
						query: {
							loginType: this.$route.query.loginType,
							loginTypeKy: this.$route.query.loginTypeKy,
						},
					});
				}
			},
			getData(isPooling = false) {
				let request = patientsHome;
				if (isPooling) {
					request = poolingPatientsHome;
				}
				request()
					.then((res) => {
						// console.log(res);
						// if (res.date == null) {
						//   return;
						// }
						if (res == false) {
							return;
						}
						this.carousel = res.data.advertising;
						let videoData = res.data.visual_consultation;
						let messageData = res.data.text_consultation;
						console.log(res.data);
						if (videoData != null) {
							if (res.data.visual_consultation.chatroom != null) {
								this.isHaveDoctorLogined = videoData.chatroom.doctor_logined;
							}
							this.isDoneShowDialog = this.isDoneShowDialog + 2;
							if (videoData.chatroom != null) {
								this.isVideoEnd = videoData.chatroom.status; //記錄問診室是否結束狀態
								if (videoData.chatroom.status === 2) {
									this.imCaseData.available = false;
								}
							}
						} else {
							this.isDoneShowDialog++;
						}
						let that = this;

						function bindData(target, data) {
							target.isExist = true;
							target.status = data.status;
							target.id = data.id;
							target.doctorName = data.doctor.name;
							if (data.type_id === 1) {
								target.date = that.$time.appointmentToFullDate(
									data.appointment_date,
									data.appointment_time
								);
							} else {
								target.date = [
									`開始時間：${that.$time.appointmentToFullDate(
                  data.appointment_date,
                  data.appointment_time
                )}`,
									`結束時間：${that.$time.appointmentToFullDate(
                  data.appointment_close_date,
                  data.appointment_time
                )}`,
								];
							}
							if (data.chatroom) {
								target.roomNumber = data.chatroom.room_number;
								target.available = true;
							} else target.available = false;
							data = that.$camel(data);
							let dateList = {
								0: data.createdAt,
								5: data.statusBookingAt,
								10: data.statusSuccessAppointmentAt,
								15: data.statusProcessingAt,
								20: data.statusWaitingReportAt,
								25: data.statusWaitingPaymentAt,
								30: data.statusWaitingDeliveryAt,
								33: data.statusRefundingAt,
								35: data.statusDeliveryAt,
								40: data.statusCompletedAt,
							};
							target.dateList = dateList;
						}
						if (videoData) {
							bindData(this.videoCaseData, videoData),
								new Date() >=
								new Date(
									videoData.appointment_date.replace(/-/g, "/") +
									" " +
									videoData.appointment_time.split("-")[0]
								) ?
								(this.videoCaseData.times = true) :
								(this.videoCaseData.times = false);
						} else {
							this.videoCaseData = {
								id: 0,
								isExist: false,
								doctorName: "",
								date: "",
								roomNumber: 0,
								available: false,
								status: 0,
								dateList: [],
							};
						}

						if (messageData) bindData(this.imCaseData, messageData);
						else
							this.imCaseData = {
								id: 0,
								isExist: false,
								doctorName: "",
								date: "",
								roomNumber: 0,
								available: false,
								status: 0,
								dateList: [],
							};
					})
					.catch((error) => {
						if (error.code === 401) return false;
						// console.log(error);
						// this.$message({
						//   type: "error",
						//   message: error.message,
						// });
					});
				// this.closeBrowser();
			},

			afterGetMemberId() {

				this.login(this.memberId, this.merchantCode, this.merchantAccessToken)
					.then(() => {
						this.getData();
						this.registerPooling();
						console.log(this.isOpenHistory);
						if (this.isOpenHistory == true || this.isOpenHistory == "true") {

							this.$store.state.uniappLogin = this.$route.query.loginType;
							this.$store.state.uniappLoginKy = this.$route.query.loginTypeKy;
							this.$store.state.uniappLoginYy = this.$route.query.loginTypeYy;
							this.isOpenHistory = "false";
							// 改变回原来值
							if (window.webBackToAndroid) {
								window.webBackToAndroid.getCloseHistory();
							}
							this.$router.replace({
								name: "caseRecords",
								query: {
									merchantCode: this.merchantCode,
									merchantAccessToken: this.merchantAccessToken,
									loginType: this.$store.state.uniappLogin,
									loginTypeYy: this.$store.state.uniappLoginYy
								}
							});
						}
					})
					.catch((err) => {
						this.$message({
							type: "error",
							message: err.message
						});
						return
						let isSa = isSafari();
						if (this.$store.state.uniappLogin) {
							window.uni.postMessage({
								data: {
									action: "update_page",
								},
							});
						}
						if (window.webBackToAndroid) {
							window.webBackToAndroid.onBack();
						}
						if (window.webkit) {
							window.webkit.messageHandlers.getDataFromWeb.postMessage("close");
						}

						if (isSa) {
							window.location.href = "iMeddy://com.imeddy.client?close=1";
						}
					});
			},
			getSystemOpen() {
				let p = navigator.platform;
				let u = navigator.userAgent;
				let is_android = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
				let is_ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
				if (
					p == "Win32" ||
					p == "Win64" ||
					p == "MacPPC" ||
					p == "MacIntel" ||
					p == "X11" ||
					p == "Linux i686"
				) {
					//PC调试环境
					console.log("PC调试环境111")
					// window.close()
					// window.location = "https://imeddy.com/"
				} else {
					if (is_android) {
						//Android终端
						console.log("Android终端")
						this.getPackName();
						// this.$message({
						// 	type: "success",
						// 	message: "Android终端",
						// });
					} else if (is_ios) {
						console.log("IOS终端")
						// this.$message({
						// 	type: "success",
						// 	message: "IOS终端",
						// });
					
					}
				}
			},

			getPackName() {
				// this.getAgent = navigator.userAgent.toLowerCase();
			},
		},
		created() {
			this.$store.state.uniappLogin = this.$route.query.loginType;
			this.$store.state.uniappLoginKy = this.$route.query.loginTypeKy;
			this.$store.state.uniappLoginYy = this.$route.query.loginTypeYy;
			if (
				this.$route.query.setReload != null &&
				this.$route.query.setReload == "reLoadingNow"
			) {
				this.$router.push({
					name: "index",
				});
				if (this.$store.state.uniappLogin) {
					return;
				}
				// location.reload();
			}
			this.getSystemOpen();
			// if(videoCaseData===2){}
			// ios头部内容适配问题
			if (this.$store.state.statusBarHeight > 0) {
				this.statusBarHeight = this.$store.state.statusBarHeight + "px";
			} else if (sessionStorage.getItem("statusBarHeight")) {
				this.statusBarHeight = sessionStorage.getItem("statusBarHeight") + "px";
			}
			//获取url中"?"符后的字串
			if (this.memberId == null) {
				var url = location.search;
				var theRequest = new Array();
				if (url.indexOf("?") != -1) {
					var str = url.substr(1);
					var strs = str.split("&");
					for (var i = 0; i < strs.length; i++) {
						theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
					}
				}
				this.memberId = theRequest.memberId;
			}

			this.memberId = this.$route.query.memberId;

			this.$store.state.uniappLogin = this.$route.query.loginType;
			this.$store.state.uniappLoginKy = this.$route.query.loginTypeKy;
			this.$store.state.uniappLoginYy = this.$route.query.loginTypeYy;

			this.merchantCode = this.$route.query.merchantCode;
			this.merchantAccessToken = this.$route.query.merchantAccessToken;


			// if (!this.token) {
			// 提供一个方法供iOS调用
			window.setMemberId = this.setMemberId;
			this.$store.state.uniappLogin = this.$route.query.loginType;
			// 安卓获取memberId
			// console.log(this.$route.query.isOpenHistory)
			if (window.webBackToAndroid) {
				this.memberId = window.webBackToAndroid.getMemberId();
				// 安卓获取是否要直接跳转记录
				this.isOpenHistory = window.webBackToAndroid.setOpenHistory();
				this.afterGetMemberId();
			}
			// iOS获取memberId
			else if (window.webkit) {
				try {
					window.webkit.messageHandlers.getDataFromWeb.postMessage("memberId");
				} catch (err) {
					// fixme: 調試專用，記得刪除
					// this.memberId = "5521ae17-234b-36c0-8fa4-a2fbc5c44e75";
					// this.memberId = "0eb3c233-59f1-36ec-bcb9-2f7f9a08308b";
					// this.afterGetMemberId();
					if (this.$route.query.memberId == null) {
						this.getData();
						this.registerPooling();
					} else {
						this.memberId = this.$route.query.memberId;
						this.isOpenHistory = this.$route.query.isOpenHistory;
						this.afterGetMemberId();
					}
				}
			} else {
				// fixme: 調試專用，記得刪除
				// this.memberId = "5521ae17-234b-36c0-8fa4-a2fbc5c44e75";
				// this.afterGetMemberId();
				if (this.$route.query.memberId == null) {
					this.getData();
					this.registerPooling();
				} else {
					this.memberId = this.$route.query.memberId;
					this.isOpenHistory = this.$route.query.isOpenHistory;
					this.afterGetMemberId();
				}
			}

			// this.closeBrowser();
		},

		destroyed() {
			clearInterval(this.poolingId);
		},
	};
</script>
<style>
	.el-message-box__btns {
		padding: 5px 15px 0;
		text-align: center;
	}
</style>

<style lang="scss" scoped>
	.setbtn-check {
		margin-left: 70%;
		margin-top: 20px;
		width: 80px;
		height: 36px;
	}

	.commonQuestions_block {
		visibility: inherit;
	}

	.commonQuestions_none {
		visibility: hidden;
	}

	.payfor_now {
		position: absolute;
		right: 9%;
		text-align: center;
		width: 39%;
		color: #fff;
		height: 30px;
		font-size: 12px;
		line-height: 30px;
		background-color: #dd5347;
		border-radius: 15px;
		padding: 0 16px;
	}

	.index_wu_p {
		margin-top: 50px;
		color: #fff;
		height: 3vh;
		font-size: 13.5vw;
	}

	.index_wu_div div {
		width: 7.5vw;
		background: #225b50;
		border: 1px solid #fff;
		border-radius: 9vh;
		font-size: 5.1vw;
		float: left;
		margin-left: 1.7vw;
	}

	.index_wu_div {
		margin: 0 auto;
		width: 78vw;
	}

	.index_wu {
		text-align: center;
		background-size: 100% 100%;
		// height:400px;
		width: 100%;
		height: 65vh;
		background-image: url("../../assets/img/index_wus-bg.png");
	}

	.frame {
		margin: 0 auto;
	}

	.reload {
		position: fixed;
		bottom: calc(var(--safe-bottom) + 8px);
		right: 8px;
		width: 50px;
		height: 50px;
		border-radius: 25px;
		background-color: white;
		box-shadow: 1px 1px 3px rgba($color: #000000, $alpha: 0.6);
		color: $green-300;
		font-size: 20px;
	}

	.carousel-img {
		width: 100%;
		object-fit: cover;
	}

	.video_frame {
		// width:85vw;
		// height:30vh;
		background-image: url("../../assets/img/sxwz.png");
		// background-size: 100% 100%;
		height: 0;
		overflow: hidden;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		-webkit-background-size: cover;
		-moz-background-size: cover;
	}

	.video_frame_gyt {
		// width:85vw;
		// height:30vh;
		background-image: url("../../assets/img/sxwz_gyt.png");
		// background-size: 100% 100%;
		height: 0;
		overflow: hidden;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		-webkit-background-size: cover;
		-moz-background-size: cover;
	}

	.video_frame_gyt button {
		width: 73%;
		background-color: #00bb91;
		color: #fff;
		box-shadow: 2px 4px 5px rgba(70, 82, 83, 0.7);
		margin-bottom: 16px;
	}

	.video_frame button {
		width: 73%;
		background-color: #00bb91;
		color: #fff;
		box-shadow: 2px 4px 5px rgba(70, 82, 83, 0.7);
		margin-bottom: 16px;
	}

	.characters_frame {
		// width:85vw;
		// height:30vh;
		background-image: url("../../assets/img/wzwz.png");
		background-size: 100% 100%;
		height: 0;
		overflow: hidden;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		-webkit-background-size: cover;
		-moz-background-size: cover;
	}

	.characters_frame_gyt {
		// width:85vw;
		// height:30vh;
		background-image: url("../../assets/img/wzwz_gyt.png");
		background-size: 100% 100%;
		height: 0;
		overflow: hidden;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		visibility: hidden;
	}

	.characters_frame_gyt button {
		width: 60%;
		background-color: #00bb91;
		color: #fff;
		box-shadow: 2px 4px 5px rgba(70, 82, 83, 0.7);
		margin-bottom: 16px;
	}

	.characters_frame button {
		width: 60%;
		background-color: #00bb91;
		color: #fff;
		box-shadow: 2px 4px 5px rgba(70, 82, 83, 0.7);
		margin-bottom: 16px;
	}

	.container {
		padding-top: var(--safe-top);
		border-bottom: var(--safe-bottom) solid $green-200;
		@include flexc-start-stretch;

		main {
			// margin-top: 8px;
			flex-grow: 1;
			background-color: #dffcf7;
			@include flexc-start-center;

			.progress-bar {
				width: 100%;
				margin: 0;
			}

			.new-reservation {
				font-size: 16px;
			}

			a {
				display: block;
			}

			img {
				width: 100%;
				// margin: 10vw 0;
			}

			&>button {
				width: 90%;
				background-color: white;
				color: $green-300;
				box-shadow: 2px 4px 5px rgba($color: $black, $alpha: 0.7);
				margin-bottom: 16px;
			}

			.case-card {
				width: 90%;
				background-color: white;
				border-radius: 19px;
				box-shadow: 2px 4px 5px rgba($color: $black, $alpha: 0.7);
				margin-bottom: 16px;
				padding: 16px;
				color: $green-300;

				.title {
					margin-bottom: 8px;
					@include flexr-start-center;

					.video-icon,
					.im-icon {
						display: inline-block;
						width: 20px;
						height: 20px;
						margin-right: 8px;
						background-size: cover;
					}

					.video-icon {
						background-image: url("../../assets/img/index-video-icon.png");
					}

					.im-icon {
						background-image: url("../../assets/img/index-im-icon.png");
					}

					.doctor-name {
						font-weight: $bold;
					}
				}

				.content {
					margin-bottom: 8px;
					font-weight: $thin;
				}

				.btn-group {
					@include flexr-center-center;

					button {
						flex-grow: 1;
						width: 40%;
					}

					button:first-of-type {
						margin-right: 16px;
					}

					button:disabled {
						background-color: rgba($color: $green-300, $alpha: 0.5);
					}
				}
			}
		}
	}
</style>